import React from "react";

const Stars = () => {
  const createStars = () => {
    const stars = [];
    for (let i = 0; i < 900; i++) {
      const style = {
        top: `${Math.random() * 100}vh`,
        left: `${Math.random() * 100}vw`,
        animationDelay: `${Math.random() * 2}s, ${Math.random() * 10}s`,
      };
      stars.push(<div key={i} className="star" style={style} />);
    }
    return stars;
  };

  return <div className="stars">{createStars()}</div>;
};

export default Stars;
